/* eslint-disable max-len */
import * as yup from "yup";

const LineItemFTLSchema = yup.object().shape({
  hazardous_materials: yup.bool(),
  linear_feet: yup.string(),
  name: yup.string().required(),
  description: yup.string().required(),
  nmfc_class: yup.number().nullable(),
  dimension_units: yup.string().required(),
  height: yup.number(),
  width: yup.number(),
  length: yup.number(),
  weight_units: yup.string().required(),
  weight: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    ),
  handling_units: yup.string().required(),
  handling_unit_count: yup.string(),
  user_weight: yup.string(),
  user_weight_units: yup.string(),
  user_length: yup.number(),
  user_width: yup.number(),
  user_height: yup.number(),
  user_dimension_units: yup.string(),
  quoteMode: yup.string().required(),
});

export default LineItemFTLSchema;
