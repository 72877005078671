export default {
  methods: {
    validateUpdateExpectedDate(newStop, chosenStop) {
      if (newStop.expected_date !== null
        && chosenStop.expected_date !== newStop.expected_date) {
        return true;
      }
      return false;
    },
  },
};
