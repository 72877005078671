<template>
  <div class="row-accessorials-responsive">
    <div class="accessorials-spinner" v-if="loading">
      <b-spinner small></b-spinner>
    </div>
    <el-collapse v-model="openedTabs" v-if="!loading">
      <el-collapse-item title="Accessorials" name="accessorials">
        <div
          v-for="accessorial of classifiedAccessorials[departmentId].pickup"
          :key="accessorial.id"
          class="row-accessorials__information"
        >
          <template v-if="accessorial.most_common">
            <b-form-checkbox
              v-model="selectedCommonAccessorials[accessorial.id]"
              @change="accessorialsCheckboxAction($event, accessorial)"
            />
            <label>{{ accessorial.name }}</label>
          </template>
        </div>
        <div
          v-for="accessorial of classifiedAccessorials[departmentId].delivery"
          :key="accessorial.id"
          class="row-accessorials__information"
        >
          <template v-if="accessorial.most_common">
            <b-form-checkbox
              v-model="selectedCommonAccessorials[accessorial.id]"
              @change="accessorialsCheckboxAction($event, accessorial)"
            />
            <label>{{ accessorial.name }}</label>
          </template>
        </div>
        <div
          v-for="accessorial of classifiedAccessorials[departmentId].general"
          :key="accessorial.id"
          class="row-accessorials__information"
        >
          <template v-if="accessorial.most_common">
            <b-form-checkbox
              v-model="selectedCommonAccessorials[accessorial.id]"
              @change="accessorialsCheckboxAction($event, accessorial)"
            />
            <label>{{ accessorial.name }}</label>
          </template>
        </div>
        <div class="button-container">
          <button v-b-modal.more-accessorials class="row-accessorials__button">
            <Badge :quantity="getChosenAccessorials" v-if="getChosenAccessorials > 0" />
            {{ $t("allOptions.moreAccessorials") }}
          </button>
        </div>
        <NonModeAccessorials class="mt-4" :nonModeAccessorials="nonModeAccessorials" />
        <CustomModal v-model="showHazmatModal" size="md" :centered="true" modalTitle="Hazmat">
          <template #modal-content>
            <HazmatInfoModal @closeHazmatModal="closeHazmatModal" />
          </template>
        </CustomModal>
        <b-modal
          centered
          static
          size="lg"
          id="more-accessorials"
          ref="more-accessorials"
          hide-header
          hide-footer
          no-close-on-backdrop
          v-model="showMoreAccessorials"
        >
          <MoreAccessorialsResponsive
            v-model="showMoreAccessorials"
            :departmentId="departmentId"
            :key="key"
            :quoteMode="quoteMode"
          />
        </b-modal>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomModal from "../../../../components/CustomModal.vue";
import HazmatInfoModal from "./components/HazmatInfoModal.vue";
import Badge from "../../../../components/Badge.vue";
import Accessorials from "../../../../Extend/Accessorials";
import MoreAccessorialsResponsive from "./MoreAccessorialsResponsive.vue";
import NonModeAccessorials from "./NonModeAccessorials.vue";

export default {
  name: "AccessorialsResponsive",
  components: {
    MoreAccessorialsResponsive,
    Badge,
    NonModeAccessorials,
    CustomModal,
    HazmatInfoModal,
  },
  mixins: [Accessorials],
  data() {
    return {
      openedTabs: ["accessorials"],
    };
  },
  computed: {
    ...mapGetters({
      classifiedAccessorials: "load/accessorials/getClassifiedAccessorials",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";

.row-accessorials {
  font-family: $font-family-portal;
  color: $color-gray-dark;
  @include font-small-standart-text;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  row-gap: 15px;
  column-gap: 3px;
  align-items: center;
  label {
    margin-top: 3px;
    color: $color-primary-company;
  }
  &__information {
    display: flex;
  }
  &__button {
    position: relative;
    @include primary-button($heightSize: 40px);
    // background-color: $color-border-container;
    border-radius: 15px;
    width: 70%;
    @include font-button-text;
    font-weight: 400;
  }
}

.button-container {
  @extend %flex-center-center;
  margin-top: 10px;
  width: 100%;
}

::v-deep {
  .el-collapse-item__header {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 95%;
    display: inline-block;
    text-align: start;
    margin-right: 8px;
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    label {
      color: $color-primary-company;
    }
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
::v-deep {
  .modal-body {
    padding: 0;
    background-color: $color-gray-light;
    border-radius: 10px;
  }

  .modal-lg {
    @media (min-width: 992px) {
      max-width: 630px;
    }
  }

  .modal-content {
    border: 1px solid $color-border-container;
    border-radius: 10px;
  }

  .spinner-border {
    color: $color-primary-company;
  }
  .spinner-border-sm {
    width: 1.5rem;
    height: 1.5rem;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    border: 0.15em solid #325FCC;
  }
}
</style>
