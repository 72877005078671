<template>
  <div class="cargo-value">
    <div
      class="input-content"
      :class="invalidCargoValue ? 'invalid-cargo-value__input' : ''"
    >
      <span class="input-content__text">{{ $t("carriers.cargoValue") }}</span>
      $
      <input
        type="text"
        min="0"
        v-model="cargoValue"
        class="input-content__input"
        :class="invalidCargoValue ? 'invalid-cargo-value__input' : ''"
        :disabled="disabledComponent"
      />
    </div>
    <span class="invalid-cargo-value" v-if="invalidCargoValue">
      {{ $t("carriers.invalidCargoValue") }}
    </span>
    <br v-if="invalidCargoValue" />
    <em class="cargo-value__info">{{ $t("carriers.addValue") }}</em>
  </div>
</template>

<script>
export default {
  name: "CargoValue",
  props: {
    cargoValueLoad: Number,
    disabledComponent: Boolean,
    invalidCargoValue: Boolean,
    selectedPlan: String
  },
  data() {
    return {
      cargoValue: "",
    };
  },
  watch: {
    selectedPlan(newValue) {
      if (newValue === "basic") {
        this.cargoValue = 0;
      }
    },
    cargoValue(newValue) {
      const result = newValue || "";
      const convertedResult = result
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => {
        this.cargoValue = convertedResult;
      });
      this.$emit("input", parseInt(convertedResult.replace(/,/g, ""), 10));
    },
  },
  created() {
    this.cargoValue = this.cargoValueLoad;
  },
};
</script>

<style lang="scss" scoped>
.cargo-value {
  text-align: start;
  &--input {
    border: 1px solid $color-border-container;
    outline: none;
  }
  &__info {
    font-weight: 400;
    font-size: 12px;
  }
}
.input-content {
  margin-top: 5px;
  padding: 5px;
  padding-left: 10px;
  background: $color-white;
  border: 1px solid $color-border-container;
  border-radius: 15px;
  @include font-input-text;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  &__input {
    border: none;
    outline: none;
    width: 100%;
  }
  &__text {
    max-width: 90px;
    width: 100%;
  }
}

.invalid-cargo-value {
  color: red;
  margin: 0;
  padding: 0;
  font-size: 10px;
  margin-left: 10px;
  &__input {
    background: rgb(253, 234, 234);
  }
}
input:disabled {
  background: transparent;
}
</style>
