<template>
  <div class="row-map">
    <l-map style="height: 300px" :zoom="zoom" :center="center">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker v-if="markerPickup !== null" :lat-lng="markerPickup" :icon="iconPickup"> </l-marker>
      <l-marker v-if="markerDelivery !== null" :lat-lng="markerDelivery" :icon="iconDelivery">
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { icon } from "leaflet";
import BlueIcon from "../../../../assets/images/location-blue-icon.png";
import GreenIcon from "../../../../assets/images/location-green-icon.png";

export default {
  name: "Map",
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 4,
      center: [37.16, -99.42],
      markerPickup: null,
      markerDelivery: null,
      zipCodePickup: null,
      ZipCodeDelivery: null,
      deliveryIcon: "",
      iconPickup: icon({
        iconUrl: BlueIcon,
        iconSize: [26, 37],
        iconAnchor: [16, 37],
      }),
      iconDelivery: icon({
        iconUrl: GreenIcon,
        iconSize: [26, 37],
        iconAnchor: [16, 37],
      }),
    };
  },
  computed: {
    ...mapGetters({
      zipCode: "load/stops/getZipCode",
    }),
  },
  watch: {
    zipCode: {
      deep: true,
      handler() {
        this.getLatLng();
      },
    },
  },
  methods: {
    async getLatLng() {
      if (this.$options.filters.checkPermission("get:pcmiller")) {
        if (this.zipCodePickup !== this.zipCode.pickup) {
          this.zipCodePickup = this.zipCode.pickup;
          if (this.zipCodePickup === null) {
            this.markerPickup = null;
          } else {
            const payload = {
              params: {
                zipCode_to: this.zipCodePickup,
              },
            };
            const resultPcMiller = await this.$store.dispatch(
              "pcmiller/getCoordinatesPcmiller",
              payload
            );
            this.markerPickup = [resultPcMiller.latitude_to, resultPcMiller.longitude_to];
          }
        }
        if (this.ZipCodeDelivery !== this.zipCode.delivery) {
          this.ZipCodeDelivery = this.zipCode.delivery;
          if (this.ZipCodeDelivery === null) {
            this.markerDelivery = null;
          } else {
            const payload = {
              params: {
                zipCode_to: this.ZipCodeDelivery,
              },
            };
            const resultPcMiller = await this.$store.dispatch(
              "pcmiller/getCoordinatesPcmiller",
              payload
            );
            this.markerDelivery = [resultPcMiller.latitude_to, resultPcMiller.longitude_to];
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.row-map {
  margin-top: 20px;
  border: 2px solid $color-border-container;
  border-radius: 20px;
}
::v-deep .leaflet-container {
  border-radius: 20px;
  z-index: 5;
}
</style>
