import * as yup from "yup";

const lineItemsDimensionsSchema = yup.object().shape({
  handling_unit_count: yup.number().required(),
  weight: yup.number().required(),
  width: yup.number().required(),
  height: yup.number().required(),
  length: yup.number().required(),
});

export default lineItemsDimensionsSchema;
