export default {
  methods: {
    validateUpdateStops(newStop, chosenStop) {
      if (newStop.location_id) {
        if (chosenStop.location_id !== newStop.location_id) {
          return true;
        }
      } else {
        if ((chosenStop.city || newStop.city)
          && chosenStop.city !== newStop.city) {
          return true;
        }
        if ((chosenStop.zip_code || newStop.zip_code)
          && chosenStop.zip_code !== newStop.zip_code) {
          return true;
        }
        if ((chosenStop.state_code || newStop.state_code)
          && chosenStop.state_code !== newStop.state_code) {
          return true;
        }
        if ((chosenStop.contact_info_id || newStop.contact_info_id)
          && chosenStop.contact_info_id !== newStop.contact_info_id) {
          return true;
        }
      }
      return false;
    },
  },
};
