<template>
  <div class="customer-information" v-if="customer">
    <ul v-if="customer.shipping_address" class="customer-information-items">
      <li class="customer-information-items__item">
        <strong>Name: </strong><span>{{ customer.name }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>Country: </strong><span>{{ customer.shipping_address.country }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>City: </strong><span>{{ customer.shipping_address.city }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>State: </strong><span>{{ customer.shipping_address.state }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>Postal Code: </strong><span>{{ customer.shipping_address.postal_code }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>Street: </strong><span>{{ customer.shipping_address.street }}</span>
      </li>
    </ul>
    <ul v-else class="customer-information-items">
      <li class="customer-information-items__item">
        <strong>Name: </strong><span>{{ customer.name }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>ID: </strong><span>{{ customer.id }}</span>
      </li>
      <li class="customer-information-items__item">
        <strong>Loyalty Tier: </strong><span>{{ customer.loyalty_tier }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CustomerInformation",
  props: {
    customer: Object,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-information-items {
  margin: 10px 0px;
  text-align: left;
  list-style-type: none;
  padding-left: 0px;
  & strong {
    color: $color-primary-company;
  }
}
</style>
