<template>
  <div v-if="nonModeAccessorials.length > 0" class="non-mode-accessorials">
    <p class="non-mode-accessorials__title">
      The following accessorials are chosen for the current load and are not available for this
      quotation mode:
    </p>
    <ul class="non-mode-accessorials__list">
      <li
        class="non-mode-accessorials__item"
        v-for="(accessorial, index) in nonModeAccessorials"
        :key="index"
      >
        - {{ accessorial.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NonModeAccessorials",
  props: {
    nonModeAccessorials: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.non-mode-accessorials {
  margin-left: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  font-style: italic;
  color: $color-error;
  &__title {
    margin: 0;
  }
  &__list {
    padding: 5px;
  }
  &__item {
    list-style-type: none;
  }
}
</style>
