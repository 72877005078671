<template>
  <div class="more-accessorials">
    <div class="accessorials-title">
      <div class="accessorials-title__title">
        <h3>{{ $t("allOptions.moreAccessorials") }}</h3>
      </div>
    </div>
    <SearchInputAccessorials v-model="searchQuery" />
    <div class="accessorials-container">
      <div class="top-line"></div>
      <div class="accessorials-group">
        <AccessorialsGroupList
          :title="$t('allOptions.general')"
          :accessorials="accessorials[departmentId].general"
          :selectedAccessorialsVmodel="selectedAccessorialsVmodel.general"
          :type="'general'"
          :quoteAccessorialList="true"
          @checkAccessorial="checkAccessorial"
        />
      </div>
      <div class="accessorials-group">
        <AccessorialsGroupList
          :title="$t('allOptions.origin')"
          :accessorials="accessorials[departmentId].pickup"
          :selectedAccessorialsVmodel="selectedAccessorialsVmodel.pickup"
          :type="'pickup'"
          :quoteAccessorialList="true"
          @checkAccessorial="checkAccessorial"
        />
      </div>
      <div class="accessorials-group">
        <AccessorialsGroupList
          :title="$t('allOptions.delivery')"
          :accessorials="accessorials[departmentId].delivery"
          :selectedAccessorialsVmodel="selectedAccessorialsVmodel.delivery"
          :type="'delivery'"
          :quoteAccessorialList="true"
          @checkAccessorial="checkAccessorial"
        />
      </div>
    </div>
    <PopoverAccessorialByEmail :brandedAccount="isBranded" :quoteMode="quoteMode" />
    <div class="add-accessorials">
      <div class="add-accessorials__button-container">
        <button @click="closeModal" class="add-accessorials__button">
          {{ $t("allOptions.saveAccessorials") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AccessorialsGroupList from "@/components/AccessorialsGroupList.vue";
import PopoverAccessorialByEmail from "./components/PopoverAccessorialByEmail.vue";
import SearchInputAccessorials from "../../../../components/SearchInputAccessorials.vue";
import MoreAccessorials from "../../../../Extend/MoreAccessorials";

export default {
  name: "MoreAccessorials",
  mixins: [MoreAccessorials],
  components: {
    SearchInputAccessorials,
    AccessorialsGroupList,
    PopoverAccessorialByEmail
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";

.more-accessorials {
  position: relative;
  padding: 5%;
  background-color: $color-gray-light;
  border-radius: 10px;
  color: $color-primary-company;
  font-family: $font-family-portal;
}
.accessorials-title {
  text-align: center;
  margin-bottom: 15px;
  color: $color-primary-title;
}
hr {
  margin-top: 0;
}
.accessorials-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.accessorials-group {
  min-height: 188px;
  &:not(:last-child) {
    border-right: 1.5px solid $color-border-container;
  }
  &__title-container {
    text-align: center;
    margin-bottom: 15px;
    @include font-standart-text;
  }
  &__checkboxes-container {
    padding: 10% 0px;
    padding-top: 5%;
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
    &:not(:last-child) {
      border-right: 1.5px solid $color-border-container;
      padding-right: 10px;
    }
  }
  &__checkbox {
    display: flex;
    justify-content: flex-start;
    padding: 2% 0px;
    padding-left: 10%;
  }
  &__text {
    @include font-standart-text;
    margin-left: 10px;
    text-align: left;
  }
  &__title {
    font-weight: bold;
  }
}
.more-accesorials-text {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}
.top-line {
  position: absolute;
  width: 100%;
  top: 14%;
  border-top: 1.5px solid $color-border-container;
}
.add-accessorials {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  &__button-container {
    width: 40%;
  }
  &__button {
    @include primary-button($heightSize: 40px);
    width: 100%;
    border-radius: 10px;
    @include font-button-text;
    font-weight: 400;
  }
}
// CSS for the scroll
/* width */
::-webkit-scrollbar {
  width: 3px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-border-container;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0097a8;
}
</style>
