<template>
  <div class="form-container">
    <AutoComplete
      :label="label"
      :suggestions="filteredAccountIds"
      :loading="loading"
      :requestLength="3"
      :disabled="routePage === 'QuoteBook' ? false : !enableEditing"
      :missingField="error === true"
      :loadingMore="loadingAccountIds"
      :key="remountSearcher"
      groupKey="sectionName"
      optionKey="name"
      valueKey="name"
      @search="getAllAccountIds($event)"
      @selectOption="selectOption"
      @optionsEndReached="getAccountsByScroll"
      v-model="selectedAccountId"
    />
  </div>
</template>
<script>
import AutoComplete from './AutoComplete.vue';

export default {
  name: "NewAccountIdsSearcher",
  components: {
    AutoComplete,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    enableEditing: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    remountSearcher: {
      type: Number,
    },
  },

  data() {
    return {
      selectedAccountId: null,
      filteredAccountIds: [{ sectionName: "Customers", options: [] }],
      skip: 0,
      limit: 10,
      routePage: "",
      accountQuery: "",
      loading: false,
      loadingAccountIds: false,
      allAccountsWereLoaded: false,
    };
  },

  created() {
    this.routePage = this.$route.name;
  },

  methods: {
    setInitialValues(event) {
      this.allAccountsWereLoaded = false;
      this.skip = 0;
      this.accountQuery = event.query;
    },

    selectOption(data) {
      this.$emit("input", data);
      this.$emit("change", data);
    },

    async getAllAccountIds(e) {
      this.$store.commit("triggerAbortController");
      this.setInitialValues(e);
      const data = {
        account_name: e.query,
        skip: this.skip,
      };
      this.loading = true;
      const response = await this.$store.dispatch("salesforce/getAllCustomers", data);
      this.loading = false;
      this.filteredAccountIds[0].options = response
        ? response.map((el) => ({ ...el, label: el.name, new_field: el.name }))
        : [];
    },

    async getAccountsByScroll() {
      if (this.loadingAccountIds || this.allAccountsWereLoaded) {
        return;
      }

      this.loadingAccountIds = true;
      this.skip += this.limit;
      const data = {
        account_name: this.accountQuery,
        skip: this.skip,
      };
      const response = await this.$store.dispatch("salesforce/getAllCustomers", data);
      const formattedResponse = response
        ? response.map((el) => ({ ...el, label: el.name, new_field: el.name }))
        : [];
      this.filteredAccountIds[0].options = [
        ...this.filteredAccountIds[0].options,
        ...formattedResponse,
      ];
      if (!response.length || response.length < 10) {
        this.allAccountsWereLoaded = true;
      }
      this.loadingAccountIds = false;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.selectedAccountId = {
            name: this.value.name,
            id: this.value.id,
            new_field: this.value.name,
          };
        }
      }
    },

    selectedAccountId() {
      if (!this.selectedAccountId) {
        this.$emit("change", this.selectedAccountId);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-container {
  width: 100%;
}
</style>
