<template>
  <div class="lineitems">
    <div class="lineitems-spinner" v-if="loading">
      <b-spinner small></b-spinner>
    </div>
    <div v-else :key="remountLineItems">
      <section
        class="lineitem-container"
        :id="'line-item' + index"
        v-for="(option, index) in selectedLineItems"
        :key="index"
      >
        <b-overlay
          :show="option.isLoading"
          spinner-small
          rounded="sm"
          class="d-flex flex-column align-items-center"
          style="margin: 7px auto"
        >
          <header class="lineitem-container__title">
            <span>{{ $t("allOptions.line") }} {{ index + 1 }}</span>
            <button
              class="remove-button"
              v-if="showDeleteButton && $route.name.toLowerCase() !== 'loadsdetails'"
              type="button"
              @click="deleteLine(index)"
            >
              <i class="ion ion-close-circle-outline remove-button__icon" title="Remove Line"></i>
            </button>
          </header>
          <div class="lineitem-row">
            <ul class="weight lineitem-inputs">
              <li
                class="weight__input"
                :class="error[index] && error[index].weight === true ? 'missing-field' : ''"
              >
                <RCInput
                  :label="$t('allOptions.weight')"
                  :floatLabel="true"
                  :positiveNumbers="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_weight"
                  @blur="dataHandling(index, 'weight')"
                />
              </li>
              <li class="weight__dropdown">
                <RCSelect
                  :id="index"
                  :suggestions="picklist.weight_units"
                  :label="$t('allOptions.weightUnits')"
                  v-model="option.user_weight_units"
                  @change="dataHandling(index, 'weight_units')"
                />
              </li>
            </ul>
            <div class="commodity lineitem-inputs">
              <RCInput
                class="commodity__input"
                :class="error[index] && error[index].description === true ? 'missing-field' : ''"
                :label="$t('allOptions.commodity')"
                :floatLabel="true"
                :type="'text'"
                :requiredField="true"
                v-model="option.description"
                @input="dataHandling(index, 'description')"
                :suggestions="commoditySuggestions"
              />
              <span class="commodity-excluded"
                >List of <u @click="openModalExcluded()"> excluded commodities</u></span
              >
            </div>
          </div>
        </b-overlay>
      </section>
    </div>
    <CustomModal v-model="excludedModal" modalTitle="Excluded Commodities" centered size="lg">
      <template #modal-content>
        <ModalExcludedCommodities :quoteMode="quoteMode" />
      </template>
    </CustomModal>
    <div class="lineitems__content-buttons">
      <button class="button-add" @click="addLine()">
        <b-icon icon="plus" font-scale="2.5"></b-icon>
        {{ $t("allOptions.addLine") }}
      </button>
    </div>
  </div>
</template>

<script>
import LineItemFTLSchema from "@/schemas/LineItemFTLSchema";
import LineItems from "../../../../Extend/LineItems";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import RCSelect from "../../../../components/RCComponents/CustomRCSelect.vue";
import CustomModal from "../../../../components/CustomModal.vue";
import ModalExcludedCommodities from "./components/ModalExcludedCommodities.vue";

export default {
  name: "LineItemsFTL",
  mixins: [LineItems],
  props: {
    lineItems: Array,
    quoteMode: String
  },
  components: {
    RCInput,
    RCSelect,
    CustomModal,
    ModalExcludedCommodities,
  },
  data() {
    return {
      totalWeight: 0,
      limitsLineItemsError: [],
      excludedModal: false
    };
  },
  watch: {
    selectedLineItems: {
      deep: true,
      handler() {
        this.calculateTotalWeight();
      },
    },
  },
  methods: {
    openModalExcluded() {
      this.excludedModal = true;
    },
    // validate the requiered lineitems ftl
    async validateFormfields() {
      const lineItems = [...this.selectedLineItems];
      let validateForm = true;
      this.error = [];
      this.limitsLineItemsError = [];
      for (let i = 0; i < lineItems.length; i += 1) {
        const itemError = {};
        try {
          // eslint-disable-next-line no-await-in-loop
          await LineItemFTLSchema.validate(lineItems[i], {
            abortEarly: false,
          });
        } catch (error) {
          validateForm = false;
          error.inner.forEach((element) => {
            itemError[element.path] = true;
            if (
              element.type === "weight-limit"
            ) {
              const errrorMessage = element.message;
              this.limitsLineItemsError.push(errrorMessage);
            }
          });
        }
        this.error.push(itemError);
      }
      let number;
      if (this.quoteMode === "ftl") {
        number = 44000;
      } else if (this.quoteMode === "reefer") {
        number = 43500;
      }
      if (this.totalWeight > number) {
        this.error[this.error.length - 1].weight = true;
      }
      return validateForm;
    },
    validateFTLLimits() {
      let number;
      if (this.quoteMode === "ftl") {
        number = 44000;
      } else if (this.quoteMode === "reefer") {
        number = 43500;
      }
      if (this.totalWeight > number) {
        return false;
      }
      return true;
    },
    async dataHandling(index, key) {
      const { loadId } = this.$route.params;
      if (key.match("weight")) {
        this.weightConverter(index);
      }
      if (loadId) {
        this.updateLineItemsInIndexedDB();
      }
      this.saveSelectedLineItemInVuex();
    },
    addLine() {
      const newLine = {
        name: `Item ${this.selectedLineItems.length + 1}`,
        handling_units: "Pallets",
        dimension_units: "in",
        weight_units: "lbs",
        nmfc_class: 0,
        height: 48,
        length: 48,
        width: 48,
        weight: null,
        user_height: 48,
        user_length: 48,
        user_width: 48,
        user_weight: "",
        user_dimension_units: "in",
        user_weight_units: "lbs",
        pickup_stop: "pickup",
        delivery_stop: "delivery",
        linear_feet: 0.1,
        description: "",
        handling_unit_count: null,
        hazardous_materials: false,
        isNewLineItem: true,
        quoteMode: this.quoteMode,
      };
      if (this.$route.params.loadId) {
        this.updateLineItemsInIndexedDB();
      }
      this.selectedLineItems.push(newLine);
      this.saveSelectedLineItemInVuex();
    },
    // FTL limits
    calculateTotalWeight() {
      this.totalWeight = 0;
      this.selectedLineItems.forEach((el) => {
        this.totalWeight += parseInt(el.weight, 10);
      });
    },
    returnLineItemsMessageError() {
      return this.limitsLineItemsError;
    }
  },
};
</script>

<style lang="scss" scoped>
// Import responsive styles
@import "@/assets/scss/components/LineItemsFTL.scss";
@import "../../../../assets/scss/components/RCComponents.scss";

// Fields styles
.lineitems-spinner {
  width: 100%;
  text-align: center;
}
.lineitem-container {
  width: 100%;
  margin-bottom: 5px;
  &:not(:last-child) {
    border-bottom: 1px solid $color-border-container;
  }
  &__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      margin: 0px;
      margin-left: 6px;
    }
  }
}
.lineitem-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0px;
  position: relative;
}
::v-deep {
  & .weight {
    @include remove-custom-component-styles;
    display: flex;
    flex-basis: 30%;
    list-style-type: none;
    border-radius: 10px;
    &__input {
      @include hide-input-number-arrows;
      width: 70%;
      & .rc-input-container {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    &__dropdown {
      width: 30%;
      & .rc-select-container {
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
  & .commodity {
    flex-basis: 70%;
    &__input {
      height: 42px;
    }
  }
}
.lineitem-inputs {
  margin: 0px 5px;
  height: 42px;
  & li {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
.commodity-excluded {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  cursor: pointer;
  font-size: 12px;
  font-style: italic;
  font-family: "Montserrat";
  u {
    margin-left: 5px;
  }
}
///****Buttons styles****\\\\
.button-add {
  display: flex;
  align-items: center;
  color: $color-primary-company;
  border: none;
  padding: 0px;
  background: none;
}
.remove-button {
  right: 0%;
  top: 0%;
  @include go-back-button;
  padding: 0 10px 0 0;
  margin: 0;
  font-weight: bold;
  &__icon {
    font-size: 25px;
  }
}

.disabled {
  pointer-events: none;
}
</style>
