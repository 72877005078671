<template>
  <div class="popover-accessorial-by-email">
    <p
      v-if="!brandedAccount && filteredSections.length > 0"
      class="more-accesorials-text"
      id="accessorial-by-email"
    >
      The <strong>following accesorials</strong> must be quoted by email
    </p>
    <b-popover target="accessorial-by-email" triggers="hover" placement="top">
      <div v-for="section in filteredSections" :key="section.mode" class="section-accessorial">
        <span>{{ section.name }}</span>
        <p>{{ section.list.join(", ") }}</p>
      </div>
    </b-popover>
  </div>
</template>

<script>
import MoreAccessorials from "../../../../../Extend/MoreAccessorials";

export default {
  name: "PopoverAccessorialByEmail",
  extends: [MoreAccessorials],
  props: {
    brandedAccount: Boolean,
    quoteMode: String,
  },
  data() {
    return {
      flatbedList: ["Dunnage", "Binder", "Chains"],
      expeditedList: [
        "Driver assistance",
        "Double o single blind",
        "Inside Pickup",
        "Inside Delivery",
        "Pick ups at offices",
        "Multi stops",
        "TSA",
      ],
      ltlList: [
        "Sort and Segregate",
        "Radioactives: White I",
        "Yellow II",
        "Carbon Black",
        "/ carpets",
        "/ e - cigars / vapes",
      ],
      ftlList: [],
      reeferList: [],
      sections: [],
    };
  },
  computed: {
    filteredSections() {
      if (this.quoteMode === "all-options") {
        return this.sections.filter((section) => section.list && section.list.length > 0);
      }
      return this.sections.filter(
        (section) => section.mode === this.quoteMode && section.list && section.list.length > 0
      );
    },
  },
  created() {
    this.sections = [
      {
        mode: "flatbed",
        name: "Accesorial FTL and FLATBED (opendeck@goglt.com)",
        list: this.flatbedList,
      },
      {
        mode: "expedited",
        name: "Accesorial EXPEDITED (specialservices@goglt.com)",
        list: this.expeditedList,
      },
      {
        mode: "ltl",
        name: "Accesorial LTL (ltl@goglt.com)",
        list: this.ltlList,
      },
      {
        mode: "ftl",
        name: "",
        list: this.ftlList,
      },
      {
        mode: "reefer",
        name: "",
        list: this.reeferList,
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.more-accesorials-text {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.section-accessorial {
  span {
    font-weight: bold;
  }
}
</style>
