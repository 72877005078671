<template>
  <div class="more-accessorials">
    <div class="accessorials-title">
      <span class="accessorials-title__title">
        {{ $t("allOptions.moreAccessorials") }}
      </span>
    </div>
    <div class="search-container">
      <i class="ion ion-search-outline"></i>
      <div class="search-container__input">
        <input type="text" class="search-input" v-model="searchQuery" placeholder="Search..." />
        <button v-show="searchQuery" @click="clearSearchFilter()" class="search-container__button">
          X
        </button>
      </div>
    </div>
    <div class="accessorials-container">
      <div class="accessorials-group">
        <div class="accessorials-group__title-container">
          <span class="accessorials-group__title">
            {{ $t("allOptions.general") }}
          </span>
        </div>
        <div class="accessorials-group__checkboxes-container">
          <template v-for="(item, index) in accessorials[departmentId].general">
            <div class="accessorials-group__checkbox" :key="index" v-if="!item.most_common">
              <b-form-checkbox
                v-model="selectedAccessorialsVmodel.general[item.id]"
                @change="selectAccessorial($event, item)"
                :id="item.id.toString()"
              />
              <label :for="item.id.toString()" class="accessorials-group__text">{{
                item.name
              }}</label>
            </div>
          </template>
          <p v-show="!accessorials[departmentId].general.length">{{ $t("noAccessorials") }}</p>
        </div>
      </div>
      <div class="accessorials-group">
        <div class="accessorials-group__title-container">
          <span class="accessorials-group__title">
            {{ $t("allOptions.origin") }}
          </span>
        </div>
        <div class="accessorials-group__checkboxes-container">
          <template v-for="(item, index) in accessorials[departmentId].pickup">
            <div v-if="!item.most_common" class="accessorials-group__checkbox" :key="index">
              <b-form-checkbox
                v-model="selectedAccessorialsVmodel.pickup[item.id]"
                @change="selectAccessorial($event, item)"
                :id="item.id.toString()"
              />
              <label :for="item.id.toString()" class="accessorials-group__text">{{
                item.name
              }}</label>
            </div>
          </template>
          <p v-show="!accessorials[departmentId].pickup.length">{{ $t("noAccessorials") }}</p>
        </div>
      </div>
      <div class="accessorials-group">
        <div class="accessorials-group__title-container">
          <span class="accessorials-group__title">
            {{ $t("allOptions.delivery") }}
          </span>
        </div>
        <div class="accessorials-group__checkboxes-container">
          <template v-for="(item, index) in accessorials[departmentId].delivery">
            <div v-if="!item.most_common" class="accessorials-group__checkbox" :key="index">
              <b-form-checkbox
                v-model="selectedAccessorialsVmodel.delivery[item.id]"
                @change="selectAccessorial($event, item)"
                :id="item.id.toString()"
              />
              <label :for="item.id.toString()" class="accessorials-group__text">{{
                item.name
              }}</label>
            </div>
          </template>
          <p v-show="!accessorials[departmentId].delivery.length">{{ $t("noAccessorials") }}</p>
        </div>
      </div>
    </div>
    <PopoverAccessorialByEmail :brandedAccount="isBranded" :quoteMode="quoteMode" />
    <div class="add-accessorials">
      <div class="add-accessorials__button-container">
        <button @click="closeModal" class="add-accessorials__button">
          {{ $t("allOptions.saveAccessorials") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MoreAccessorialsResponsive from "../../../../Extend/MoreAccessorials";
import PopoverAccessorialByEmail from "./components/PopoverAccessorialByEmail.vue";

export default {
  name: "MoreAccessorialsResponsive",
  mixins: [MoreAccessorialsResponsive],
  components: {
    PopoverAccessorialByEmail
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";

.more-accessorials {
  position: relative;
  padding: 3% 5%;
  background-color: $color-gray-light;
  border-radius: 10px;
  color: $color-primary-company;
  font-family: $font-family-portal;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0px;
  background: none;
  border: none;
  color: $color-primary-company;
  &__icon {
    font-size: 35px;
  }
}
.accessorials-title {
  text-align: center;
  margin: 25px 0px;
  &__title {
    font-weight: bold;
    @include font-section-title;
    padding-top: 10px;
  }
}

.accessorials-container {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.accessorials-group {
  &__title-container {
    text-align: start;
    margin-left: 40px;
    margin-top: 10px;
    @include font-standart-text;
  }
  &__checkboxes-container {
    padding: 0px;
    padding-top: 2%;
    &:not(:last-child) {
      border-right: 1.5px solid $color-border-container;
      padding-right: 10px;
    }
  }
  &__checkbox {
    display: flex;
    justify-content: flex-start;
    padding: 0% 0px;
    padding-left: 10%;
  }
  &__text {
    margin-left: 10px;
    text-align: left;
  }
  &__title {
    font-weight: bold;
    margin: 15px 0px 8px 0px;
  }
}

.add-accessorials {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  &__button-container {
    width: 60%;
  }
  &__button {
    @include primary-button($heightSize: 40px);
    @extend %flex-center-center;
    margin-bottom: 10%;
    background-color: $color-border-container;
    border-radius: 10px;
    @include font-button-text;
    font-weight: 400;
    &:hover {
      background: $color-primary-trigger-button-hover;
    }
  }
}
.search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
  margin: 20px auto;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  background-color: white;
  i {
    font-size: 17.5px;
    color: $color-border-container;
    margin: auto 5px;
  }
  &__input {
    width: 100%;
  }
  &__button {
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
    }
  }
}
.search-input {
  width: 90%;
  height: 100%;
  border: none;
  color: $color-primary-company;
  &:focus {
    color: $color-primary-company;
    outline: none;
  }
}
// CSS for the scroll
/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-border-container;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0097a8;
}
::-webkit-scrollbar-corner {
  background-color: $color-gray-light;
}
</style>
